import React from 'react'

import demo1 from '../../images/demo/1.png'
import demo2 from '../../images/demo/2.png'
import demo3 from '../../images/demo/3.png'
import demo4 from '../../images/demo/5.png'
import demo5 from '../../images/demo/4.png'
import demo6 from '../../images/demo/6.png'
import demo7 from '../../images/demo/7.png'

const Demo = () => {
    return (
        <section className="wpo-demo-section section-padding" id="demo">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col col-lg-4">
                        <div className="wpo-section-title">
                            <h2>Unique Home Pages</h2>
                            <p>Unique HTML5 Template for the Consulting Business</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="wpo-demo-grids clearfix">
                            <div className="grid" >
                                <div className="inner">
                                    <a href="https://wpocean.com/html/tf/consoel/" target="_blank"><img src={demo1} alt="" /></a>
                                </div>
                                <h3>Home page default</h3>
                            </div>
                            <div className="grid">
                                <div className="inner">
                                    <a href="https://wpocean.com/html/tf/consoel/index-5.html" target="_blank"><img src={demo5} alt="" /></a>
                                </div>
                                <h3>Home Style 2</h3>
                            </div>
                            <div className="grid">
                                <div className="inner">
                                    <a href="https://wpocean.com/html/tf/consoel/index-7.html" target="_blank"><img src={demo7} alt="" /></a>
                                </div>
                                <h3>Home Style 3</h3>
                            </div>
                            <div className="grid">
                                <div className="inner">
                                    <a href="https://wpocean.com/html/tf/consoel/index-4.html" target="_blank"><img src={demo4} alt="" /></a>
                                </div>
                                <h3>Home Style 4</h3>
                            </div>
                            <div className="grid">
                                <div className="inner">
                                    <a href="https://wpocean.com/html/tf/consoel/index-2.html" target="_blank"><img src={demo2} alt="" /></a>
                                </div>
                                <h3>Home Style 5</h3>
                            </div>
                            <div className="grid">
                                <div className="inner">
                                    <a href="https://wpocean.com/html/tf/consoel/index-6.html" target="_blank"><img src={demo6} alt="" /></a>
                                </div>
                                <h3>Home Style 6</h3>
                            </div>
                            <div className="grid">
                                <div className="inner">
                                    <a href="https://wpocean.com/html/tf/consoel/index-3.html" target="_blank"><img src={demo3} alt="" /></a>
                                </div>
                                <h3>Home Style 7</h3>
                            </div>
                            <div className="grid">
                                <div className="other-demo">
                                    <div className="other-demo-ball">
                                        <h3>Other Demo</h3>
                                        <span>Coming Soon..</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>

    )
}

export default Demo;